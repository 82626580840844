import React, { createContext, useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import { getLocalItem } from "../../../utils/localStorage";
import dayjs from "dayjs";
import useCart from "../../../hooks/use-cart";
interface Props {
  children: React.ReactNode;
}

export enum ProductCode {
  White = "WHITE_START24_PHONE",
  Black = "BLACK_START24_PHONE",
}
export interface ShipmentProduct {
  productName: string;
  productCode: string;
  localProductCode: string;
  localProductCountryCode: string;
  networkTypeCode: string;
  isCustomerAgreement: false;
  weight: {
    volumetric: number;
    provided: number;
    unitOfMeasurement: string;
  };
  totalPrice: [
    {
      currencyType: string;
      priceCurrency: string;
      price: number;
    }
  ];
  totalPriceBreakdown: [
    {
      currencyType: string;
      priceCurrency: string;
      priceBreakdown: [
        {
          typeCode: string;
          price: number;
        }
      ];
    }
  ];
  detailedPriceBreakdown: [
    {
      currencyType: string;
      priceCurrency: string;
      breakdown: [
        {
          name: string;
          serviceCode: string;
          localServiceCode: string;
          typeCode: string;
          serviceTypeCode: string;
          price: number;
          priceCurrency: string;
          isCustomerAgreement: false;
          isMarketedService: false;
          isBillingServiceIndicator: false;
          priceBreakdown: [
            {
              priceType: string;
              typeCode: string;
              price: number;
              rate: number;
              basePrice: number;
            }
          ];
        }
      ];
    }
  ];
  pickupCapabilities: {
    nextBusinessDay: false;
    localCutoffDateAndTime: string;
    GMTCutoffTime: string;
    pickupEarliest: string;
    pickupLatest: string;
    originServiceAreaCode: string;
    originFacilityAreaCode: string;
    pickupAdditionalDays: number;
    pickupDayOfWeek: number;
  };
  deliveryCapabilities: {
    deliveryTypeCode: string;
    estimatedDeliveryDateAndTime: string;
    destinationServiceAreaCode: string;
    destinationFacilityAreaCode: string;
    deliveryAdditionalDays: number;
    deliveryDayOfWeek: number;
    totalTransitDays: number;
  };
  items: [
    {
      number: number;
      breakdown: [
        {
          name: string;
          serviceCode: string;
          localServiceCode: string;
          typeCode: string;
          serviceTypeCode: string;
          price: number;
          priceCurrency: string;
          isCustomerAgreement: false;
          isMarketedService: false;
          isBillingServiceIndicator: false;
          priceBreakdown: [
            {
              priceType: string;
              typeCode: string;
              price: number;
              rate: number;
              basePrice: number;
            }
          ];
        }
      ];
    }
  ];
  pricingDate: string;
}
export interface ProductI {
  product_code: string;
  color: string;
  discount: number;
  quantity: number;
  price: number;
  total_price: number;
}
export interface InfoI {
  full_name: string;
  phone_code: string;
  phone_number: string;
  email: string;
}
export interface ShippingI {
  country_id: number;
  country: string;
  country_code: string;
  state_id: number;
  state: string;
  state_code: string;
  city?: string;
  city_id?: number;
  address: string;
  postal_code: string;
  receiver_phone_code: string;
  receiver_phone_number: string;
  company_name?: string;
  apartment?: string;
  shipment_product?: ShipmentProduct;
  shipment_amount?: number;
  shipment_product_code?: string;
}

export interface PaymentI {
  info?: InfoI;
  shipping?: Partial<ShippingI>;
  referral?: string;
  discount?: number;
}
export interface IReferral {
  ref_code: string;
  discount: number;
}

interface PaymentContextType {
  data: PaymentI | null;
  setData: React.Dispatch<React.SetStateAction<PaymentI | null>>;
  mode: number;
  setMode: React.Dispatch<React.SetStateAction<number>>;
  resetData: () => void;
  toggle: boolean;
  ToggleState: () => void;
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseModal: () => void;
  setDiscount: (value: number, ref?: string) => void;
}

// Create the context
const PaymentContext = createContext<PaymentContextType>({
  data: null,
  /*eslint-disable */
  setData: () => {},
  mode: 0, //:0 add  1:update 2:view
  setMode: () => {},
  resetData: () => {},
  toggle: false,
  ToggleState: () => {},
  isModalOpened: false,
  setIsModalOpened: () => {},
  onCloseModal: () => {},
  setDiscount: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const PaymentContextProvider = (props: Props) => {
  const [data, setData] = useState<PaymentI | null>(null);
  const [mode, setMode] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const { cart } = useCart();
  const ToggleState = () => {
    setToggle(!toggle);
  };
  const resetData = () => {
    setData(null);
  };

  const onCloseModal = () => {
    setMode(0);
    setIsModalOpened(false);
    setData(null);
  };
  const setDiscount = (value: number, ref?: string) => {
    setData((prev) => ({
      ...prev,
      referral: ref || "",
      discount: value,
    }));
  };

  // useEffect(() => {
  //   const savedRefCode = getLocalItem("refCode");
  //   const refCodeExpiredTime = getLocalItem("refCodeExpiredTime");
  //   if (
  //     savedRefCode &&
  //     refCodeExpiredTime &&
  //     dayjs().unix() < +refCodeExpiredTime
  //   ) {
  //     setDiscount(20, savedRefCode);
  //   }
  // }, []);
  useEffect(() => {
    if (cart.refCode) {
      setDiscount(20, cart.refCode);
    }
  }, [cart]);
  return (
    <PaymentContext.Provider
      value={{
        data,
        setData,
        mode,
        setMode,
        resetData,
        toggle,
        ToggleState,
        isModalOpened,
        setIsModalOpened,
        onCloseModal,
        setDiscount,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

export const PaymentOutlet = (props: any) => {
  return (
    <PaymentContextProvider>
      {props.children ? props.children : <Outlet />}
    </PaymentContextProvider>
  );
};
export default PaymentContext;
