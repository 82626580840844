import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

const ZipCode = ({
  country,
  city,
  district,
}: {
  country?: string;
  city?: string;
  district?: string;
}) => {
  const { t } = useTranslation();
  let concatString = country + " " + city + " " + district + " zip code";
  concatString = concatString.replace(/ /g, "+");

  const queryString = `search?q=${concatString}&oq=${concatString}&num=1`;
  const googleUrl = `https://www.google.com.vn/${queryString}`;
  return (
    <Container>
      <span>{t("Quickly find your zip code")}&nbsp;</span>
      <a href={googleUrl} target="_blank" rel="noreferrer">
        {t("here")}.
      </a>
    </Container>
  );
};

export default ZipCode;

const Container = styled.div`
  font-family: "Archivo";
  font-size: 1.4rem;
  font-style: italic;
  color: #8f8f8f;
  margin-top: -1rem;
  margin-bottom: 1.8rem;
  > a,
  > a:active,
  > a:visited {
    color: #0066ff;
    font-weight: 600;
  }
`;
