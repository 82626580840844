import React, { Dispatch, SetStateAction } from "react";
import { styled } from "styled-components";
import { ButtonGradient } from "../../../components/ButtonGradient";
import { OrderStatusType } from "../instances";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const BtnConfirm = styled(ButtonGradient)`
  width: 100%;
  padding: 1.4rem 4.8rem;
  color: var(--Grey-0, #f7f7f7);
  text-align: center;

  /* Button */
  font-family: Archivo;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:disabled {
    background: #bcbcbc;
    background-image: none;
    color: #fff;
    filter: contrast(1);
  }
`;

const NeedAHelp = styled.div`
  color: var(--Blue, #1268e9);
  text-align: center;
  font-family: "Archivo";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 0.8rem;
  cursor: pointer;
`;

const RequestBtn = ({
  isOpen,
  isOpenReason,
  orderStatus,
}: {
  isOpen: Dispatch<SetStateAction<boolean>>;
  isOpenReason?: Dispatch<SetStateAction<boolean>>;
  orderStatus?: string;
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  return (
    <>
      {orderStatus === OrderStatusType.PendingPayment ? (
        <BtnConfirm
          onClick={() => {
            navigate("/purchase/payment");
          }}
        >
          {t("Payment")}
        </BtnConfirm>
      ) : (
        <BtnConfirm
          onClick={() => {
            isOpen(false);
            if (isOpenReason) {
              isOpenReason(true);
            }
          }}
          disabled={orderStatus !== OrderStatusType.InProgress}
        >
          {t("Cancel Order")}
        </BtnConfirm>
      )}

      {(orderStatus === OrderStatusType.Confirm ||
        orderStatus === OrderStatusType.Packing ||
        orderStatus === OrderStatusType.Shipping) && (
        <NeedAHelp
          onClick={() => {
            isOpen(false);
            searchParams.set("cancel-form", "1");
            setSearchParams(searchParams);
          }}
        >
          {t("Need a help?")}
        </NeedAHelp>
      )}
    </>
  );
};

export default RequestBtn;
