import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { styled } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

const Container = styled.div``;
const CustomToastStyled = styled(ToastContainer)`
  position: fixed;
  right: 0;
  top: 50px;
  font-size: 1.6rem;
  z-index: 999999999999999999999999;
`;
const OutletContainer = styled.div`
  z-index: 99999;
`;
function CustomToast() {
  return (
    <Container>
      <CustomToastStyled />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </Container>
  );
}

export default CustomToast;
