// @ts-nocheck
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ISelect, InputFieldI } from ".";
import Select from "react-select";
import { breakPoints } from "../../constant/breakpoints";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  @media (max-width: ${breakPoints.mobile}) {
    row-gap: 0.5rem;
  }
  .input-select {
    height: 4rem;
    /* margin-bottom: 1.8rem; */
    min-width: 12rem;
    max-width: 12rem;
    color: var(--grey-90, #222);
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    outline: none !important;
    &:focus-within {
      border: none;
    }
  }
  .input-select > input {
    min-width: 5rem !important;
    max-width: 5rem !important;
  }
  .select__control {
    border: 1px solid #0000008a !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

const Input = styled.input<{ error?: boolean }>`
  height: 4rem;
  font-family: "Archivo";
  border: 1px solid ${({ error }) => (error ? "rgb(246 79 89)" : "#0000008a")};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  &:focus {
    outline: none !important;
  }
  /* @media (max-width: 1280px) {
    height: 3.5rem;
  } */
  @media (max-width: 820px) {
    margin-bottom: 1.5rem;
  }
`;

const TextField = styled.div`
  font-size: 2rem;
  margin-bottom: 1.2rem;
  font-weight: 600;

  @media (max-width: 1280px) {
    font-size: 1.7rem;
  }
  @media (max-width: 820px) {
    margin-bottom: 1rem;
  }
`;

const FieldContainer = styled.div``;

export const CustomRenderOption = styled.div`
  /* padding-left: 1rem; */
  cursor: pointer;
  > span {
    font-family: "Twemoji Country Flags";
  }
`;

const Step1 = ({
  fullNameInput,
  phoneNumberInput,
  emailInput,
  listCountries,
  phoneCode,
  onPhoneCodeChangeHandler,
}: {
  fullNameInput: InputFieldI;
  phoneNumberInput: InputFieldI;
  emailInput: InputFieldI;
  listCountries: any[];
  phoneCode: ISelect;
  onPhoneCodeChangeHandler: (event: any) => void;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const phoneCodeOptions = listCountries.filter(
    (country) => country?.phone_code !== ""
  );
  const removedCharacters = phoneCodeOptions.map((code) => {
    return { ...code, phone_code_sort: +code.phone_code.replace(/\D/g, "") };
  });

  removedCharacters.sort((a, b) =>
    a.phone_code_sort > b.phone_code_sort ? 1 : -1
  );

  const uniquePhoneCodeOptions = [
    ...new Map(
      removedCharacters.map((item) => [item["phone_code_sort"], item])
    ).values(),
  ];

  const formattedPhoneCodes = uniquePhoneCodeOptions.map((code) => {
    return {
      label: code.phone_code,
      value: code.phone_code,
      code: code.phone_code,
      emoji: code.emoji,
    };
  });
  const validName = new RegExp(/^[\p{L} ]+$/u);
  const isValidName = validName.test(fullNameInput.value);

  return (
    <Container>
      <FieldContainer>
        <TextField>{t("Full Name")} (*)</TextField>
        <Input
          value={fullNameInput.value}
          onChange={fullNameInput.valueChangeHandler}
          onKeyDown={(e) => {
            if (!validName.test(e.key)) {
              e.preventDefault();
            }
          }}
          error={fullNameInput.error}
          onFocus={() => {
            fullNameInput.setError(false);
          }}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField>{t("Phone Number")} (*)</TextField>

        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <Select
              value={phoneCode}
              classNamePrefix="select"
              className="input-select"
              onChange={onPhoneCodeChangeHandler}
              options={formattedPhoneCodes}
              styles={{}}
              formatOptionLabel={({ value, label, emoji }) => (
                <CustomRenderOption>
                  <span>
                    {!label?.includes("+") && <span>+&nbsp;</span>}
                    {label} {emoji}
                  </span>
                </CustomRenderOption>
              )}
            />
          </div>
          <Input
            type="number"
            value={phoneNumberInput.value}
            onChange={phoneNumberInput.valueChangeHandler}
            error={phoneNumberInput.error}
            onFocus={() => {
              phoneNumberInput.setError(false);
            }}
          />
        </div>
      </FieldContainer>

      <FieldContainer>
        <TextField>{t("Email")} (*)</TextField>
        <Input
          value={emailInput.value}
          onChange={emailInput.valueChangeHandler}
          error={emailInput.error}
          onFocus={() => {
            emailInput.setError(false);
          }}
          type="email"
          required
        />
      </FieldContainer>
    </Container>
  );
};

export default Step1;
