
import { WagmiConfig, createConfig, configureChains, mainnet, Chain } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'


export const PoolTestnet: Chain = {
    id: +(process.env.REACT_APP_NETWORK_CHAIN_ID || 12345),
    name: "Pools Chain",
    network: "pools-chain-testnet",
    nativeCurrency: {
        decimals: 18,
        name: "Pools",
        symbol: "Pool💲",
    },
    rpcUrls: {
        public: {
            http: ["https://rpc-testnet.poolsmobility.com"]
        },
        default:
        {
            http: ["https://rpc-testnet.poolsmobility.com"]
        }
    },
    blockExplorers: {
        default: { name: "PScan", url: "https://scan-testnet.poolsmobility.com" },
    },
    testnet: true,
};

// export const bscMainnet: Chain = {
//     id: 56,
//     name: "BNB Smart Chain",
//     network: "bsc",
//     rpcUrls: {
//         public: "https://bsc-dataseed4.defibit.io/",
//         default: "https://bsc-dataseed4.defibit.io/",
//     },
//     blockExplorers: {
//         default: { name: "BscScan", url: "https://bscscan.com" },
//     },
//     nativeCurrency: {
//         name: "Binance Chain Native Token",
//         symbol: "BNB",
//         decimals: 18,
//     },
// };


// export const { chains, publicClient, webSocketPublicClient } = configureChains(
//     [bscTestnet],
//     [publicProvider()]
// );

// export const metaMaskConnector = new MetaMaskConnector({
//     chains,
//     options: {
//         shimDisconnect: true,
//     },
// });

// export const config = createConfig({
//     autoConnect: true,
//     publicClient,
//     webSocketPublicClient,
//     // connectors: [metaMaskConnector],
// });


const { chains, publicClient, webSocketPublicClient } = configureChains(
    [PoolTestnet],
    [publicProvider()],
)

export const config = createConfig({
    autoConnect: true,
    connectors: [new InjectedConnector({ chains })],
    publicClient,
    webSocketPublicClient,
})
