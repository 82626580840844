import React, { ReactNode } from "react";
import { styled } from "styled-components";
import CloseIcon from "../../icons/Close";
import { breakPoints } from "../../constant/breakpoints";
const Modal = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  ${({ isOpen }) =>
    isOpen
      ? `
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  `
      : `
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  `}
  >div {
    max-width: 90vw;
    max-height: 90dvh;
    position: relative;
    > svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    > div {
      max-width: 95vw;
    }
    > div > svg {
      max-width: 2.5rem;
    }
  }
`;

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const CustomModal = ({ children, isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()}>
        <CloseIcon onClick={onClose} />
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
