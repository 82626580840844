import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { LANGUAGES_CODE } from "../components/LanguageSelector";

interface Props {
  children: React.ReactNode;
}
// Define the context interface
interface ThemeType {
  fontFamily: string;
  setTheme: (language: string) => void;
}

// Create the context
const ThemeContext = createContext<ThemeType | null>(null);

// Custom Provider component
export const ThemeContextProvider = (props: Props) => {
  const [fontFamily, setFontFamily] = useState(
    `"NeueMachina","NeueMachinaBackup"`
  );
  const setTheme = (language: string) => {
    switch (language) {
      case LANGUAGES_CODE.English:
        setFontFamily(`"NeueMachina","NeueMachinaBackup"`);
        break;
      case LANGUAGES_CODE.Vietnamese:
        setFontFamily("Monda");
        break;
      default:
        setFontFamily(`"NeueMachina","NeueMachinaBackup"`);
        break;
    }
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      const formattedLang = lang.replaceAll(`"`, ``);
      setTheme(formattedLang);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        fontFamily,
        setTheme,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export const ThemeOutlet = (props: any) => {
  return (
    <ThemeContextProvider>
      {props.children ? props.children : <Outlet />}
    </ThemeContextProvider>
  );
};
export default ThemeContext;
