import { ReactNode, useState, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { breakPoints } from "../../constant/breakpoints";

const Layout = styled.div<{ open: boolean }>`
  display: flex;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  left: 0px;
  top: 0px;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overscroll-behavior-y: none;
  background: #00000096;
  z-index: ${({ open }) => (open ? 9999 : -99999)};
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  @media (max-width: ${breakPoints.ipad}) {
    align-items: flex-start;
  }
`;

const Main = styled.div<{
  bg: string;
  borderRadius: string;
  p: string;
  open: boolean;
}>`
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ p }) => p};
  background: ${({ bg }) => bg};
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  z-index: ${({ open }) => (open ? 999999 : -99999)};

  @media (max-width: ${breakPoints.ipad}) {
    margin-top: 10rem;
    max-height: 90dvh;
  }
`;

const useModal = () => {
  const [open, isOpen] = useState<boolean>(false);
  // const squareBoxRef = useRef<HTMLDivElement>(null);
  // const clickOutsidehandler = () => {
  //   isOpen(false);
  // };
  // useOnClickOutside(squareBoxRef, clickOutsidehandler);

  const Modal = ({
    children,
    bg,
    borderRadius,
    p,
  }: {
    children: ReactNode;
    bg?: string;
    borderRadius?: string;
    p?: string;
  }) => {
    return (
      <Layout open={open} onClick={() => isOpen(false)}>
        <Main
          open={open}
          // ref={squareBoxRef}
          bg={bg ? bg : "white"}
          borderRadius={borderRadius ? borderRadius : "2rem"}
          p={p ? p : "5rem 7rem"}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Main>
      </Layout>
    );
  };

  return { Modal: Modal, open, isOpen };
};

export default useModal;
