import React, { useContext, useRef, useState } from "react";
import CustomModal from "../../../../components/modal/CustomModal";
import { styled } from "styled-components";
import { ButtonGradient } from "../../../../components/ButtonGradient";
import PaymentContext from "../../context/payment-context";
import useCart from "../../../../hooks/use-cart";
import ClearIcon from "../../../Purchase/icons/ClearIcon";
import { removeLocalItem } from "../../../../utils/localStorage";
import Api from "../../../../service";
import { breakPoints } from "../../../../constant/breakpoints";
import { useTranslation } from "react-i18next";

const ReferralModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { cart, updateRefCode } = useCart();
  const { data, setData, setDiscount } = useContext(PaymentContext);
  const referralInputRef = useRef(null);
  const [invalidReferral, setInvalidReferral] = useState(false);
  const [referral, setReferral] = useState<string>(
    data?.referral || cart?.refCode || ""
  );
  const { t } = useTranslation();

  const onCheckReferralCode = async () => {
    if (referral) {
      try {
        const response = await Api.User.checkRefCode({ ref_code: referral });
        if (response && response.data) {
          const data = response.data;
          setInvalidReferral(!data.is_valid);
          if (data.is_valid) {
            setDiscount(data.discount_amount, referral);
            updateRefCode(referral);
            onClose();
          }
        }
      } catch (error) {
        setDiscount(0, "");
        setInvalidReferral(true);
      }
    } else {
      setInvalidReferral(false);
      setDiscount(0, "");
      updateRefCode("");
      onClose();
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>{t("Referral Code")}</Title>
        <Body>
          <label>{t("Enter Referral Code")}: </label>
          <Referral>
            <div>
              <input
                ref={referralInputRef}
                value={referral}
                onChange={(e) => {
                  setReferral(e.target.value);
                }}
                type="text"
                placeholder={t("Add referral code to get discount")}
                onFocus={() => {
                  setInvalidReferral(false);
                }}
              />
              {referral && (
                <div
                  onClick={() => {
                    removeLocalItem("refCode");
                    removeLocalItem("refCodeExpiredTime");
                    setReferral("");
                    //@ts-ignore
                    referralInputRef?.current?.focus();
                    // setDiscount(0);
                    // setData((prev) => ({
                    //   referral,
                    //   discount: 0,
                    // }));
                  }}
                >
                  <ClearIcon />
                </div>
              )}
            </div>
            {invalidReferral && (
              <InvalidReferral>
                <span>{t("Invalid code, please try again")}</span>
              </InvalidReferral>
            )}
            {/* <span>{t("Add referral code to get discount")}</span> */}
          </Referral>
        </Body>
        <UpdateBtn onClick={onCheckReferralCode}>{t("Update")}</UpdateBtn>
      </Container>
    </CustomModal>
  );
};

export default ReferralModal;

const Container = styled.div`
  padding: 4rem 2rem;
  border-radius: 2rem;
  min-width: 50rem;
  background-color: #fff;
  @media (max-width: ${breakPoints.mobile}) {
    min-width: 0;
    width: 90vw;
    padding: 4rem;
  }
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 2rem;
  > label {
    font-size: 1.6rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    > label {
      font-size: 1.4rem;
    }
  }
`;
const Title = styled.h3`
  color: #2d2d2d;
  text-align: center;
  font-family: "Archivo";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 2rem;
  }
`;
const UpdateBtn = styled(ButtonGradient)`
  margin-top: 4rem;
  width: 100%;
  padding: 1rem 0;
  font-size: 1.8rem;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
    padding: 0.8rem 0;
  }
`;

const Referral = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  > p {
    font-family: "Archivo";
    color: #515151;
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
  }
  > div {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1.2rem 0.8rem;
    border-radius: 0.8rem;
    background: #f2f2f2;
    > input {
      font-family: "Archivo";
      flex: 1;
      border: none;
      background-color: transparent;
      color: #232323;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      &:focus {
        outline: none;
      }
      @media (max-width: ${breakPoints.mobile}) {
        font-size: 1.2rem;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: var(--purple, #b43bf0);
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    @media (max-width: ${breakPoints.mobile}) {
      padding: 0.8rem;
    }
  }
  > span {
    font-family: "Archivo";
    color: #1268e9;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.012rem;
  }
`;

const InvalidReferral = styled.div`
  background: transparent !important;
  padding: 0 !important;
  > span {
    color: #d0011b;
    font-family: Archivo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 300;
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 1.2rem;
    }
  }
`;
