import { useContext } from 'react'
import CartContext from '../context/cart-context';
const useCart = () => {
    const cartCtx = useContext(CartContext);
    if (!cartCtx) throw new Error('useCart must be used within a CartProvider');


    return cartCtx;
}

export default useCart