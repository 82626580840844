import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { styled } from "styled-components";
import DHLIcon from "../../icons/dhl.svg";
import StripeIcon from "../../icons/stripe.svg";
import PaypalIcon from "../../icons/paypal.svg";
import { ButtonGradient } from "../../../../components/ButtonGradient";
import { PaymentMethod } from "../..";
import EditIcon from "../../icons/edit.svg";
import { breakPoints } from "../../../../constant/breakpoints";
import CartItemsWithParams from "../../../../components/cart/CartItemsWithParams";
import { IOrderTracking } from "../../../orderTracking";
import RequestBtn from "../../../orderTracking/components/RequestBtn";
import BillingInfo from "./BillingInfo";
import ShippingInfo from "./ShippingInfo";
import Summary from "./Summary";
import Tracking from "./Tracking";
import {
  useAccount,
  useBalance,
  useConnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import useCart from "../../../../hooks/use-cart";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Container = styled.div``;
const Header = styled.div`
  h1 {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: center;
    font-family: "Archivo";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: center;

    /* BODY 1 */
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 3.6rem */
    letter-spacing: 0.02rem;
  }

  @media (max-width: ${breakPoints.mobile}) {
    h1 {
      font-size: 2rem;
    }
    p {
      margin-top: 1rem;
      font-size: 1.4rem;
      line-height: 150%; /* 2.72rem */
      letter-spacing: 0.016rem;
    }
  }
`;
export const ShippingContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid var(--gray-scale-black-20, #d1d1d1);
  h3 {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.018rem;
  }
`;
export const ShippingRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    min-width: 30%;
    justify-self: flex-start;
    color: var(--gray-scale-black-60, #525252);
    font-family: Archivo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.016rem;
  }
  > span {
    justify-self: flex-end;
    color: var(--grey-90, #111);
    text-align: right;
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    svg {
      width: 2rem;
      height: 2rem;
    }
    img {
      margin: 0 0.5rem;
      max-width: 2rem;
      max-height: 2rem;
    }
  }
  p {
    cursor: pointer;
  }

  @media (max-width: ${breakPoints.mobile}) {
    label {
      max-width: 50%;
      font-size: 1.4rem;
    }
    > span {
      max-width: 40%;
      font-size: 1.4rem;
      /* direction: rtl; */
    }
  }
`;
export const DHL = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  > span {
    text-align: right;
    font-size: 1.6rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    span {
      font-size: 1.4rem;
    }
  }
`;
const ProductDetailContainer = styled.div`
  margin-top: 2rem;
  padding: 0 2rem;
  h3 {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.018rem;
  }
`;
const ItemsContainer = styled.div`
  margin-top: 1rem;
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 0.1rem;
    max-width: 70%;
    p {
      max-width: 100%;
      color: #19252c;
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 2.08rem */
      letter-spacing: 0.08rem;
    }
  }
  span {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: right;
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 2.6rem */
  }
`;
export const ProductSummaryContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const FinalTotal = styled(ShippingRow)`
  margin-top: 1rem;
  font-size: 2rem;
  label {
    color: var(--gray-scale-black-60, #525252);
    font-weight: 700;
  }
  span {
    color: var(--grey-90, #111);
    text-align: right;
    font-weight: 700;
  }
  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img {
      max-width: 2.4rem;
      max-height: 2.4rem;
    }
  }
`;

const BtnContainer = styled.div`
  margin-top: 4rem;
  width: 100%;
  text-align: center;
`;
const BtnConfirm = styled(ButtonGradient)`
  padding: 1.4rem 4.8rem;
  color: var(--Grey-0, #f7f7f7);
  text-align: center;

  /* Button */
  font-family: Archivo;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  label {
    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: right;
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 2.6rem */
    text-decoration: line-through;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  span {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(18, 18, 18, 0.7);

    color: var(--gray-scale-white, #fff);
    text-align: right;
    font-family: "Archivo";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const BreakLine = styled.div`
  background: #b1b1b1;
  display: block;
  width: 100%;
  height: 0.1rem;
  margin-top: 2rem;
`;

export const Email = styled.span`
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Warning = styled.div`
  margin-top: 2rem;
  max-width: 55rem;
  text-align: left;
  color: #ff2a2aeb;
  font-size: 1.5rem;
`;

export enum ConfirmMode {
  Payment = "payment",
  Tracking = "tracking",
}

const PaymentConfirmation = ({
  handleStep3,
  setStep,
  isOpen,
  isOpenReason,
  orderInfo,
  mode = ConfirmMode.Payment,
  tracking,
}: {
  handleStep3?: () => Promise<void>;
  setStep?: Dispatch<SetStateAction<number>>;
  isOpen: Dispatch<SetStateAction<boolean>>;
  isOpenReason?: Dispatch<SetStateAction<boolean>>;
  orderInfo: IOrderTracking;
  mode?: ConfirmMode;
  tracking?: boolean;
}) => {
  const { address } = useAccount();
  const balanceData = useBalance({ address });
  const balance = Number(balanceData.data?.value) / 10 ** 18;
  const { connect, connectors } = useConnect();
  const { chain } = useNetwork();
  const { switchNetwork, isLoading } = useSwitchNetwork();
  const NETWORK_CHAIN_ID = process.env.REACT_APP_NETWORK_CHAIN_ID;

  const { t } = useTranslation();

  const [metamaskIsInstalled, setMetamaskIsInstalled] =
    useState<boolean>(false);

  useEffect(() => {
    if (!window) return;
    if (typeof window.ethereum !== "undefined") {
      setMetamaskIsInstalled(true);
    }
  }, []);

  if (!orderInfo) {
    return null;
  }
  const { payment: data, cart, method } = orderInfo;

  const onConfirmHandler = async () => {
    if (method !== PaymentMethod.Pools && handleStep3) {
      return handleStep3();
    }
    if (cart.totalPrice > balance) {
      toast.error("Insufficient balance");
      return;
    }
    handleStep3?.();
  };
  const onSwitchNetwork = async () => {
    if (!NETWORK_CHAIN_ID) return;
    if (chain) {
      switchNetwork?.(+NETWORK_CHAIN_ID);
    }
  };
  const onInstallMetamask = async () => {
    window.open(
      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
      "_blank"
    );
  };

  const insufficientBalance = cart.totalPrice > balance;
  const wrongNetwork =
    NETWORK_CHAIN_ID && chain && +chain?.id !== +NETWORK_CHAIN_ID;

  return (
    <Container>
      <Header>
        <h1>{t("Order Confirmation")}</h1>
        <p>{t("Please review your payment information below")}</p>
      </Header>
      {tracking && <Tracking />}
      <BillingInfo
        setStep={setStep}
        isOpen={isOpen}
        data={data}
        mode={mode}
        tracking={tracking}
      />
      <ShippingInfo setStep={setStep} isOpen={isOpen} data={data} mode={mode} />

      <ProductDetailContainer>
        <h3>{t("Product Detail")}</h3>

        <ItemsContainer>
          <CartItemsWithParams
            cart={cart}
            method={method}
            tracking={tracking}
          />
        </ItemsContainer>

        <BreakLine />
        <Summary
          data={data}
          totalPrice={cart.totalPrice}
          method={method}
          tracking={tracking}
        />
      </ProductDetailContainer>
      <BtnContainer>
        {mode === ConfirmMode.Payment &&
          (method === PaymentMethod.Pools ? (
            metamaskIsInstalled ? (
              !address ? (
                <>
                  {connectors.map((connector) => (
                    <BtnConfirm onClick={() => connect({ connector })}>
                      {t("Connect Wallet")}
                    </BtnConfirm>
                  ))}
                </>
              ) : wrongNetwork ? (
                <BtnConfirm onClick={onSwitchNetwork} disable={isLoading}>
                  {isLoading ? `${t("Switching")}...` : t("Switch Network")}
                </BtnConfirm>
              ) : insufficientBalance ? (
                <BtnConfirm disabled={true}>
                  {t("Insufficient Balance")}
                </BtnConfirm>
              ) : (
                <BtnConfirm onClick={onConfirmHandler}>
                  {t("Confirm")}
                </BtnConfirm>
              )
            ) : (
              <BtnConfirm onClick={onInstallMetamask}>
                {t("Install Metamask")}
              </BtnConfirm>
            )
          ) : (
            <BtnConfirm onClick={onConfirmHandler}>{t("Confirm")}</BtnConfirm>
          ))}

        {wrongNetwork ? (
          <Warning>
            {t(
              "Warning: Wrong network. Please connect to appropriate network. POOLS network."
            )}
          </Warning>
        ) : (
          insufficientBalance && (
            <Warning>
              {t(
                "Warning: You do not have enough POOLS in your wallet to pay transaction fees on POOLS Mainnet network. Please try again or deposit from another account."
              )}
            </Warning>
          )
        )}
        {mode === ConfirmMode.Tracking &&
          orderInfo.orderStatus &&
          orderInfo._id && (
            <RequestBtn
              isOpen={isOpen}
              isOpenReason={isOpenReason}
              orderStatus={orderInfo.orderStatus}
            />
          )}
      </BtnContainer>
    </Container>
  );
};

export default PaymentConfirmation;
