import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import useCart from "../../../../hooks/use-cart";
import { IItem } from "../../../../context/cart-context";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Input = styled.input`
  max-width: 5rem;
  text-align: center;
  border: none;

  color: var(--gray-scale-black-80, #2d2d2d);
  text-align: center;
  font-family: "Archivo";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 2.72rem */
  letter-spacing: 0.016rem;
`;
const QuantityInput = ({ item }: { item: IItem }) => {
  const { setNewItem, removeItems } = useCart();

  const { quantity: quantityInput } = item;
  const [quantity, setQuantity] = useState<number>(quantityInput);
  const { t } = useTranslation();

  useEffect(() => {
    setQuantity((prev) => quantityInput);
  }, [quantityInput]);

  return (
    <Input
      value={quantity}
      onChange={(e) => {
        setQuantity((prev) => +e.target.value);
      }}
      onBlur={(e) => {
        if (+e.target.value > 10) {
          setQuantity(10);
          toast.error(t("You have reached the order limit (maximum 10)"));
        }
        if (+e.target.value < 1) {
          removeItems(item.product);
        } else {
          setNewItem({
            product: item.product,
            quantity: +e.target.value > 10 ? 10 : +e.target.value,
          });
        }
      }}
    />
  );
};

export default React.memo(QuantityInput);
