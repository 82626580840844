import { ProductCode } from "../context/payment-context";

export const PaymentTestData = {
    customer_name: 'nvdai',
    customer_email: 'nvdai1506@playgroundvina.com',
    phone_number: '84132123132',
    receiver_phone_number: '8413212313200',
    company_name: 'Company',
    apartment: 'aparment',
    address: "123 strerrt",
    country_code: 'VN',
    state_code: 'SG',
    city_id: 3811,
    shipment_product_code: 'T',
    shipment_product: '{"productName":"EXPRESS DOMESTIC","productCode":"N","localProductCode":"N","localProductCountryCode":"CZ","networkTypeCode":"TD","isCustomerAgreement":false,"weight":{"volumetric":0,"provided":1.5,"unitOfMeasurement":"metric"},"totalPrice":[{"currencyType":"BILLC","priceCurrency":"GBP","price":141.51}],"totalPriceBreakdown":[{"currencyType":"BILLC","priceCurrency":"GBP","priceBreakdown":[{"typeCode":"SPRQT","price":114.92}]}],"detailedPriceBreakdown":[{"currencyType":"BILLC","priceCurrency":"GBP","breakdown":[{"name":"12:00 PREMIUM","serviceCode":"YK","localServiceCode":"YK","typeCode":"string","serviceTypeCode":"SCH","price":5,"priceCurrency":"GBP","isCustomerAgreement":false,"isMarketedService":false,"isBillingServiceIndicator":false,"priceBreakdown":[{"priceType":"TAX","typeCode":"All Bu","price":0,"rate":0,"basePrice":5}]}]}],"pickupCapabilities":{"nextBusinessDay":false,"localCutoffDateAndTime":"2019-09-18T15:00:00","GMTCutoffTime":"16:00:00","pickupEarliest":"09:30:00","pickupLatest":"16:00:00","originServiceAreaCode":"ELA","originFacilityAreaCode":"HHR","pickupAdditionalDays":0,"pickupDayOfWeek":3},"deliveryCapabilities":{"deliveryTypeCode":"QDDC","estimatedDeliveryDateAndTime":"2019-09-20T12:00:00","destinationServiceAreaCode":"PRG","destinationFacilityAreaCode":"PR3","deliveryAdditionalDays":0,"deliveryDayOfWeek":5,"totalTransitDays":2},"items":[{"number":1,"breakdown":[{"name":"DUTY","serviceCode":"II","localServiceCode":"II","typeCode":"DUTY","serviceTypeCode":"FEE","price":20,"priceCurrency":"CZK","isCustomerAgreement":false,"isMarketedService":false,"isBillingServiceIndicator":false,"priceBreakdown":[{"priceType":"P","typeCode":"TAX","price":110,"rate":10,"basePrice":100}]}]}],"pricingDate":"2020-02-25"}',
    zip_code: '123333',
    details: [
        {
            product_code: ProductCode.Black,
            quantity: 2
        },
        {
            product_code: ProductCode.White,
            quantity: 3
        }
    ]
}   