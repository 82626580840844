export const getLocalItem = (key: string) => {
  return JSON.parse(localStorage.getItem(key) as any);
};

export const setLocalItem = (key: string, item: any) => {
  return localStorage.setItem(key, JSON.stringify(item));
};

export const removeLocalItem = (key: string) => {
  return localStorage.removeItem(key);
};
